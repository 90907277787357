import React, {useState} from 'react';
import styled from "@emotion/styled";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const DescriptionWrapper = styled.div`
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: bold;
`

const ApiKeyInput = styled.input`
  margin-bottom: 16px;
  padding: 8px;
  border-radius: 4px;
  text-align: center;
  border: none;
  width: 360px;
  background-color: rgb(69, 73, 86);
  font-weight: bold;
  color: white;
  font-family: Quicksand, serif;

  &: focus {
    outline: none
  }
`
const ArrowWrapper = styled.div`
  position: absolute;
  right: 16px;
  margin: auto 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 200ms ease-in-out;
  cursor: pointer;

  &: hover {
    color: lightgrey;
  }
`

interface Props {
  onNext: (apiKey: string) => void
}

const ApiKeyScreen: React.FC<Props> = ({onNext}) => {
  const [apiKey, setApiKey] = useState<string>('')

  const handleNext = () => {
    onNext(apiKey);
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setApiKey(event.target.value)
  }

  return (
    <Wrapper>
      <DescriptionWrapper>
        To upload your data, enter your API Key
      </DescriptionWrapper>
      <ApiKeyInput type="text" placeholder="API Key" onChange={handleChange} value={apiKey}/>
      <ArrowWrapper onClick={handleNext}>
        <ArrowForwardIcon/>
      </ArrowWrapper>
    </Wrapper>
  )
};

export default ApiKeyScreen;
