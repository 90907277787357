import React, {useRef, useState, useMemo} from 'react';
import DataImporter from "./data-importer-screen";
import styled from '@emotion/styled';
import logo from './segna-logo-teal-white.png';
import ApiKeyScreen from "./api-key-screen";
import {animated, useSpring} from '@react-spring/web';
import {useLocation} from "react-router-dom";
import ConstructionIcon from '@mui/icons-material/Construction';


const Wrapper = styled.div`
  position: relative;
  background-color: rgb(26, 32, 45);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
`

const FooterContent = styled.div`
  position: absolute;
  bottom: 16px;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
`

const LogoWrapper = styled.img`
  margin-bottom: 16px;
`

const ContentSection = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  border-radius: 4px;
  width: 500px;
  height: 120px;
  background-color: rgb(40, 45, 60);
`

const AnimatedDiv = styled(animated.div)`
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
`

const MisconfiguredWrapper = styled.div`
  flex: 1;
  text-align: center;
  color: #f1a400;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
`

const MisconfigurationSubtext = styled.div`
  color: white;
  font-size: 0.9rem;
  margin-top: 8px;
`

export const useQuery = () => {
  const {search} = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function App() {
  const refForInitialLoad = useRef<HTMLInputElement | null>(null);

  const query = useQuery();
  const authFromUrl = query.get('auth');
  const apiKeyFromUrl = authFromUrl && window.atob(authFromUrl)
  const pipelineId = query.get('pipelineId') || ''

  const [apiKey, setApiKey] = useState<string | null>(apiKeyFromUrl);
  const [isDataImporterStep, setIsDataImporterStep] = useState<boolean>(Boolean(apiKeyFromUrl))

  const styles = useSpring({
    to: {opacity: 1, x: 0},
    from: {opacity: 0, x: isDataImporterStep ? 100 : -100},
    reset: true,
    immediate: !refForInitialLoad.current
  })

  const onBack = () => {
    setIsDataImporterStep(false)
  }

  const onNext = (apiKey: string) => {
    setApiKey(apiKey);
    setIsDataImporterStep(true);
  }

  return (
    <Wrapper ref={refForInitialLoad}>
      <LogoWrapper src={logo} className="App-logo" alt="logo"/>
      <ContentSection>
        {pipelineId ?
          <AnimatedDiv style={styles}>
            {isDataImporterStep ?
              <DataImporter onBack={onBack} apiKey={apiKey}/>
              :
              <ApiKeyScreen onNext={onNext}/>
            }
          </AnimatedDiv> :
          <MisconfiguredWrapper>
            <ConstructionIcon fontSize="large"/>
            The Data Importer is not configured correctly.
            <MisconfigurationSubtext>Please ensure you are using the right URL, or contact the team if the issue persists.</MisconfigurationSubtext>
          </MisconfiguredWrapper>
        }
      </ContentSection>
      <FooterContent>
        <div style={{marginRight: 32}}><a href={"https://www.segna.io/terms-conditions"} target="_blank_"
                                          style={{color: 'white'}}>Terms and Conditions</a></div>
        <div> Copyright 2022 Segna Inc. All Rights Reserved.
        </div>
      </FooterContent>
    </Wrapper>
  );
}

export default App;
