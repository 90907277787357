import React, {useEffect} from 'react';
import segna, {DataImporter} from "@segna/segna-sdk";
import styled from "@emotion/styled";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useQuery} from "../App";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const DescriptionWrapper = styled.div`
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: bold;
`

const ArrowWrapper = styled.div`
  position: absolute;
  left: 16px;
  margin: auto 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 200ms ease-in-out;
  cursor: pointer;

  &: hover {
    color: lightgrey;
  }
`


interface Props {
  onBack: () => void;
  apiKey: string | null;
}

const DataImporterWrapper: React.FC<Props> = ({onBack, apiKey}) => {
  const query = useQuery();
  const authFromUrl = Boolean(query.get('auth'));
  const pipelineId = query.get('pipelineId') || ''

  useEffect(() => {
    segna.initialize({
      apiKey: apiKey || ''
    })
  }, [apiKey])

  return (
    <Wrapper>
      {!authFromUrl && <ArrowWrapper onClick={onBack}>
          <ArrowBackIcon/>
      </ArrowWrapper>}
      <DescriptionWrapper>
        Click Import to upload your file!
      </DescriptionWrapper>
      <DataImporter pipelineId={pipelineId}/>
    </Wrapper>
  );
};

export default DataImporterWrapper;
